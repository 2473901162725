<template>
  <section>
    <div class="row">
      <div class="col-12">
        <div class="panel-sec">
          <div class="panel-sec__header">
            <span><slot name="header"></slot></span>
            <span
              v-if="!collapsed"
              :aria-expanded="collapsed ? 'true' : 'false'"
              aria-controls="collapse-note"
              class="arrow-indicator fa fa-chevron-up cursor-pointer"
              @click="toggle"
            ></span>
            <span
              v-else
              :aria-expanded="collapsed ? 'true' : 'false'"
              aria-controls="collapse-note"
              class="arrow-indicator fa fa-chevron-down cursor-pointer"
              @click="toggle"
            ></span>
          </div>
          <b-collapse
            id="collapse-note"
            v-model="collapsed"
            class="panel-sec__content"
          >
            <template>
              <slot name="content"></slot>
            </template>
          </b-collapse>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "DetailPanel",
  data() {
    return {
      collapsed: false,
    };
  },
  computed: {},
  methods: {
    toggle() {
      this.collapsed = !this.collapsed;
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-sec {
  border: 1px solid #eae7e7;
  margin: 1em 0;
  &__header {
    border-bottom: 1px solid #eae7e7;
    padding: 8px;
    font-size: 1.0625rem;
    font-weight: 700;
    color: #32325d;
    .arrow-indicator {
      float: right;
      font-size: 1.2em;
    }
  }
  &__content {
    padding: 1em 1em;
    /* .desc {
      margin-bottom: 1.5em;
      font-size: 0.7749rem;
      font-style: normal;
      white-space: normal;
      border-bottom: 1px solid #f6f3f3;
      padding-bottom: 0.5em;
      .person-text {
        margin-bottom: 8px;
        color: #969494;
        &__name {
          color: #7e7979;
          font-weight: 600;
          font-size: 0.85rem;
        }
        &__time {
          font-size: 0.7rem;
        }
      }
      &:nth-last-of-type(1) {
        border-bottom: 0px;
        padding-bottom: 0;
      }
    } */
  }
}
</style>
