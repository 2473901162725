<template>
  <progress-loader :show="loading.container">
    <div class="flight-content">
      <div class="section dt-detail-info">
        <div class="row dt-detail-info__header">
          <div class="col-md-12 heading">Airport Details# {{ item.id }}</div>
        </div>
        <div class="dt-detail-info__content-sec">
          <div class="pt-3">
            <div class="row">
              <div class="col-md-3">
                {{ item.code }}
                <div v-if="item.lat || item.long" class="pt-3">
                  <div class="row">
                    <div class="col-md-3">{{ item.lat }}</div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">{{ item.long }}</div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                {{ item.name }}
              </div>
              <div class="col-md-2">
                <badge
                  v-if="item.status === 'active'"
                  type="success"
                  :rounded="true"
                  size="md"
                >
                  {{ item.status }}
                </badge>
                <badge v-else type="danger" :rounded="true" size="md">
                  {{ item.status }}
                </badge>
              </div>

              <div v-if="item.id != '1'" class="col-md-4 misc-details">
                <span class="misc-details__val">
                  <base-checkbox
                    v-model="item.is_active"
                    input-classes="input-sm"
                    container-class="check-item"
                    :inline="true"
                    :busy="loading.statusOnly"
                    label-text="Status"
                    @change="changeStatusOnly()"
                  ></base-checkbox>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="dt-detail-info__content-sec">
          <div class="row misc-wrapper">
            <div class="col-md-3 misc-details">
              <span class="misc-details__label">Airport fees:</span>
              <span class="misc-details__val">
                {{ item.airport_fees || 0 }}€
              </span>
            </div>

            <div class="col-md-3 misc-details">
              <span class="misc-details__label">Day only:</span>
              <span class="misc-details__val check-list">
                <base-checkbox
                  v-model="item.is_day_only"
                  input-classes="input-sm"
                  container-class="check-item"
                  :busy="loading.dayOnly"
                  @change="changeDayOnly()"
                ></base-checkbox>
              </span>
            </div>
          </div>
        </div>
        <!-- <div class="dt-detail-info__content-sec">
          <div class="row misc-wrapper">
            <div class="col-md-3 misc-details">
              <span class="misc-details__label">Airport fees:</span>
              <span class="misc-details__val">
                {{ item.airport_fees || 0 }}€
              </span>
            </div>
          </div>
        </div> -->
        <div class="dt-detail-info__content-sec">
          <div class="row">
            <div class="col-12">
              <description :airport="item"></description>
            </div>
          </div>
        </div>
        <div class="dt-detail-info__content-sec">
          <div class="row">
            <div class="col-md-3">
              <span class="h3 font-weight-bolder">Picture:</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <airport-file-picker
                ref="imgFileInput"
                :airport="item"
                :submit-on-change="true"
                @is-busy="isBusy($event, 'img')"
              ></airport-file-picker>
              <div class="list-action-btn">
                <div class="list-action-btn__item">
                  <i class="fas fa-upload"></i>
                  <span @click="onBrowseImage()">Change</span>
                </div>
                <div class="list-action-btn__item">
                  <i class="fas fa-link"></i>
                  <span :id="mapLinkIdentifier" @click="addMap()">
                    Add Map Link
                  </span>
                </div>
                <map-link-popup
                  :show="showMapLinkPopup"
                  :airport="item"
                  :target="mapLinkIdentifier"
                  @close="showMapLinkPopup = false"
                ></map-link-popup>
              </div>
            </div>
          </div>
        </div>

        <div class="dt-detail-info__content-sec mt-4">
          <notes-form :add-new="addNote"></notes-form>
          <note-card :note-list="item.comments"></note-card>
        </div>
      </div>
    </div>
    <div v-if="item.id != 1" class="dt-detail-footer">
      <div class="action-list">
        <div class="action-list__item">
          <a href="javascript:void(0)" @click="deleteAirport()">
            Delete airport
          </a>
        </div>
      </div>
    </div>
  </progress-loader>
</template>

<script>
import { airportService, confirmDialogService } from "@/core/services";

import NotesForm from "@views/common/NotesForm.vue";
import NoteCard from "@views/common/NoteCard.vue";
import Description from "./Description.vue";
import MapLinkPopup from "./MapLinkPopup.vue";
import AirportFilePicker from "./AirportFilePicker.vue";
import { AirportModel } from "@/core/models";

export default {
  name: "Detail",
  components: {
    NotesForm,
    MapLinkPopup,
    NoteCard,
    AirportFilePicker,
    Description,
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return new AirportModel();
      },
    },
    tableRef: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      showMapLinkPopup: false,
      loading: {
        container: false,
        img: false,
        dayOnly: false,
        statusOnly: false,
      },
    };
  },
  computed: {
    mapLinkIdentifier() {
      return "map-link-" + this.item.id;
    },
  },
  methods: {
    onBrowseImage() {
      const fileInputElement =
        this.$refs.imgFileInput.$el.querySelector(".file-img-input");
      fileInputElement.click();
    },
    deleteAirport() {
      confirmDialogService.open(
        "Are you sure you want to delete this airport?",
        () => {
          this.loading.container = true;
          //console.log(this.item.id);
          //this.tableRef.toggleExpand(this.item);
          airportService
            .remove(this.item.id)
            .then((_res) => {
              this.tableRef.toggleExpand(this.item);
              airportService.drop(this.item.id);
            })
            .finally(() => (this.loading.container = false));
        }
      );
    },
    addMap() {
      this.showMapLinkPopup = true;
      /* confirmDialogService.open(
        "Are you sure you want to delete this airport?"
      ); */
    },
    isBusy($event, type) {
      this.loading[type] = $event;
    },
    changeDayOnly() {
      this.loading.dayOnly = true;

      airportService
        .saveAttribute(this.item.id, { is_day_only: this.item.is_day_only })
        .finally(() => (this.loading.dayOnly = false));
    },
    changeStatusOnly() {
      this.loading.statusOnly = true;

      airportService
        .saveAttribute(this.item.id, { is_active: this.item.is_active })
        .finally(() => (this.loading.statusOnly = false));
    },
    addNote(payload) {
      return airportService.saveNote(this.item.id, payload);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .form-input {
    position: relative;
    .file-img-input {
      position: absolute;
      top: 0;
      opacity: 0;
      height: 100%;
      width: 100%;
      cursor: pointer;
      z-index: 100;
    }
    .file-img-preview {
      height: 200px;
      border: 1px solid #c4d9e4;
    }
  }
}
</style>
