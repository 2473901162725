<template>
  <section>
    <div class="row">
      <div class="col-12">
        <div class="note-sec">
          <div class="note-sec__header">
            <span>All Notes</span>
            <span
              v-if="!collapsed"
              :aria-expanded="collapsed ? 'true' : 'false'"
              aria-controls="collapse-note"
              class="arrow-indicator fa fa-chevron-up cursor-pointer"
              @click="toggle"
            ></span>
            <span
              v-else
              :aria-expanded="collapsed ? 'true' : 'false'"
              aria-controls="collapse-note"
              class="arrow-indicator fa fa-chevron-down cursor-pointer"
              @click="toggle"
            ></span>
          </div>
          <b-collapse
            id="collapse-note"
            v-model="collapsed"
            class="note-sec__content"
          >
            <template v-if="sourceList.length > 0">
              <div v-for="item in sourceList" :key="item.id" class="desc">
                <div class="person-text">
                  <div class="person-text__name">{{ item.userName }}</div>
                  <div class="person-text__time">
                    {{ item.comment_date }} at
                    <span class="pl-1">{{ item.comment_time }}</span>
                  </div>
                </div>
                <div class="content-text">{{ item.comment }}</div>
              </div>
            </template>

            <div v-else class="text-center">
              <h4>No Notes found.</h4>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { DateTime } from "@/core/utils";
export default {
  props: {
    noteList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      collapsed: false,
    };
  },
  computed: {
    sourceList() {
      //const authUser = this.$store.getters["auth/user"];

      return this.noteList.map((item) => {
        if (item.user_id) {
          /* item.userName =
            authUser.id != item.user_id.id ? item.user_id.full_name : "Me"; */
          item.userName = item.user_id.full_name;
          item.comment_date = DateTime.format(item.created_at, "MMM DD, YYYY");
          item.comment_time = DateTime.format(item.created_at, "hh:mm A");
        } else {
          item.userName = "Unknown";
        }
        return item;
      });
    },
  },
  methods: {
    toggle() {
      this.collapsed = !this.collapsed;
    },
  },
};
</script>

<style lang="scss" scoped>
.note-sec {
  border: 1px solid #eae7e7;
  margin: 1em 0;
  &__header {
    border-bottom: 1px solid #eae7e7;
    padding: 8px;
    font-size: 1.0625rem;
    font-weight: 700;
    color: #32325d;
    .arrow-indicator {
      float: right;
      font-size: 1.2em;
    }
  }
  &__content {
    padding: 1em 1em;
    .desc {
      margin-bottom: 1.5em;
      font-size: 0.7749rem;
      font-style: normal;
      white-space: normal;
      border-bottom: 1px solid #f6f3f3;
      padding-bottom: 0.5em;
      .person-text {
        margin-bottom: 8px;
        color: #969494;
        &__name {
          color: #7e7979;
          font-weight: 600;
          font-size: 0.85rem;
        }
        &__time {
          font-size: 0.7rem;
        }
      }
      &:nth-last-of-type(1) {
        border-bottom: 0px;
        padding-bottom: 0;
      }
    }
  }
}
</style>
