<template>
  <div class="note-sec">
    <div class="note-sec__control">
      <div
        v-for="(noteRow, noteRowIdx) in noteRows"
        :key="noteRow.id"
        class="row"
      >
        <div class="col-md-6 note-form">
          <div class="note-form__control">
            <base-input>
              <textarea
                v-model="noteRow.comment"
                placeholder="Write Note..."
                class="form-control"
                rows="3"
                resize="none"
              ></textarea>
            </base-input>
          </div>
          <div class="note-form__btn-actions">
            <base-button
              :loading="noteRow.busy"
              size="sm"
              type="primary"
              @click="save(noteRow, noteRowIdx)"
            >
              <span class="fas fa-save"></span>
              Save
            </base-button>
            <base-button size="sm" type="secondary" @click="cancelNote()">
              <span class="fas fa-times"></span>
              Cancel
            </base-button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="noteRows.length == 0" class="note-sec__action">
      <base-button size="sm" type="primary" @click="addNote()">
        <span class="fas fa-plus"></span>
        Add Note
      </base-button>
    </div>
  </div>
</template>

<script>
import { Helper } from "@core/utils";
import { notificationService } from "@/core/services";

export default {
  props: {
    showLegModalForm: {
      type: Boolean,
      default: false,
    },
    addNew: {
      type: Function,
    },
  },
  data() {
    return {
      noteRows: [],
    };
  },
  computed: {},
  methods: {
    save(note, idx) {
      const payload = {
        comment: note.comment,
      };
      note.busy = true;
      this.addNew(payload)
        .then((_) => {
          notificationService.success("Note has been added successfully");
          this.removeNote(note, idx);
        })
        .finally(() => (note.busy = false));
    },
    close() {
      this.isShowModal = false;
      this.$emit("close");
    },
    addNote() {
      this.noteRows.push({
        id: Helper.uid(),
        busy: false,
        comment: "",
      });
    },
    cancelNote() {
      this.noteRows = [];
    },
    removeNote(_note, noteIdx) {
      this.noteRows.splice(noteIdx, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.note-sec {
  .note-form {
    display: flex;
    &__control {
      flex-basis: 80%;
    }
    &__btn-actions {
      display: flex;
      flex-direction: column;
      margin-left: 0.5em;
      button {
        margin-right: 0;
        margin-bottom: 0.5em;
      }
    }
  }
}
</style>
