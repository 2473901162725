<template>
  <base-poperover
    custom-class="popover--lg"
    :show="show"
    :target="target"
    style="max-width: 20rem"
    @hidden="onHidden"
    @shown="onShown"
  >
    <template #title>
      <span class="close" aria-label="Close" @click="onClose">
        <div class="d-inline-block" aria-hidden="true">&times;</div>
      </span>
      Add Map Link
    </template>
    <div class="map-link">
      <div class="map-link__control">
        <div class="row">
          <div class="col-12">
            <base-input
              v-model="mapLink"
              placeholder="Enter map link... "
            ></base-input>
          </div>
        </div>
      </div>
      <div class="popover__action">
        <base-button icon type="danger" size="sm" @click="onClose">
          <span class="btn-inner--icon">
            <i class="fas fa-times"></i>
          </span>
        </base-button>
        <base-button
          icon
          type="success"
          size="sm"
          :loading="loading"
          :disabled="isDisabled"
          @click="onSave()"
        >
          <span v-if="!loading" class="btn-inner--icon">
            <i class="fas fa-check"></i>
          </span>
        </base-button>
      </div>
    </div>
  </base-poperover>
</template>

<script>
import { airportService, notificationService } from "@/core/services";
export default {
  name: "MapLinkPopup",
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    target: {
      default: "",
      type: String,
    },
    airport: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      mapLink: "",
      loading: false,
    };
  },
  computed: {
    airportMapLink() {
      return (this.airport && this.airport.map_location) || "";
    },
    isDisabled() {
      return this.mapLink && this.mapLink.trim().length > 0 ? false : true;
    },
  },
  methods: {
    onSave() {
      //this.onClose();
      //this.updateLink();
      const payload = {
        map_location: this.mapLink,
      };

      this.loading = true;

      airportService
        .saveAttribute(this.airport.id, payload)
        .then(() => {
          notificationService.success(
            "Airport map link has been updated successfully"
          );
          this.onClose();
        })
        .finally(() => (this.loading = false));
    },
    onClose() {
      this.$emit("close");
    },
    onHidden() {
      //this.mapLink = "";
    },
    onShown() {
      this.mapLink = this.airportMapLink;
    },
  },
};
</script>
