<template>
  <div class="form-input">
    <div class="img-preview">
      <!-- <img
                    class="file-img-preview"
                    :src="item.default_img"
                    alt=""
                  />
                  <img
                    alt=""
                    class="file-img-preview"
                    src="@assets/img/no-img.jpg"
                  /> -->
      <base-image
        :src="selectedImg || airport.image_url"
        :busy="loading"
        style-class="file-img-preview"
      ></base-image>
    </div>

    <input
      :disabled="loading"
      class="file-img-input"
      type="file"
      accept="image/*"
      title=""
      @change="changeImage"
    />
  </div>
</template>

<script>
import { airportService } from "@/core/services";
export default {
  name: "AirportFilePicker",
  props: {
    airport: {
      type: Object,
      default: () => {
        return {};
      },
    },
    submitOnChange: {
      type: Boolean,
      default: false,
    },
    previewImg: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      imgFile: null,
      selectedImg: null,
    };
  },
  computed: {
    filepickerElement() {
      return document.querySelector(".file-img-input");
    },
  },
  methods: {
    changeImage($event) {
      const selectedFile = $event.target.files[0];
      this.imgFile = selectedFile;

      if (this.submitOnChange) {
        this.loading = true;
        this.$emit("is-busy", true);
        this.uploadImage(this.airport.id);
      } else {
        if (this.imgFile) {
          this.previewImage($event);
          this.$emit("select-file", this.imgFile);
        } else {
          this.removeImage();
        }
      }
    },
    uploadImage(airportID) {
      if (this.imgFile) {
        return airportService
          .uploadImage(airportID, this.imgFile)
          .finally(() => {
            this.loading = false;
            this.$emit("is-busy", false);
          });
      }
    },
    previewImage(event) {
      if (event.target.files.length > 0) {
        const src = URL.createObjectURL(event.target.files[0]);
        this.selectedImg = src;
        // preview.style.display = "block";
      }
    },
    removeImage() {
      this.selectedImg = null;
      this.imgFile = null;
      this.filepickerElement.value = "";
      this.$emit("select-file", null);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-input {
  position: relative;
  .file-img-input {
    position: absolute;
    top: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    z-index: 100;
  }
  ::v-deep {
    .img-preview {
      .file-img-preview {
        max-height: 220px;
        border: 1px solid #c4d9e4;
        width: 100%;
      }
    }
  }
}
</style>
