<template>
  <div>
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0 breadcrum-heading">
            Airports
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-card no-body class="mb-2">
        <b-card-body>
          <b-row>
            <b-col>
              <data-table
                ref="dt"
                :table-columns="columns"
                :table-data="airportList"
                row-class-name="row-expanded"
                :border="true"
                :loading="loading"
                :expanded="true"
                :row-actions="rowActions"
              >
                <template #header-action>
                  <base-button
                    v-permission="[GRANT.ADD_AIRPORT]"
                    size="md"
                    type="primary"
                    @click="toggleAddAirportForm(true)"
                  >
                    <span class="fas fa-plus"></span>
                    Add Airport
                  </base-button>
                </template>
                <template #expandable="{ item }">
                  <detail :item="item" :table-ref="dtRef"></detail>
                </template>
              </data-table>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-container>
    <add-airport
      :show-add-airport-modal-form="showAddAirportModalForm"
      :item="itemData"
      @close="toggleAddAirportForm(false)"
    ></add-airport>
  </div>
</template>

<script>
// import { confirmDialogService } from "@/core/services";

import { RouteBreadCrumb } from "@/components";
import AddAirport from "./components/AddAirport.vue";
import Detail from "./components/Detail.vue";

import { airportService } from "@/core/services";
import { mapGetters } from "vuex";
import { GRANT } from "@/core/enums";

export default {
  name: "Airports",
  components: { AddAirport, RouteBreadCrumb, Detail },
  data() {
    return {
      editeOption: false,
      columns: [
        {
          prop: "code",
          label: "Code",
          sortable: true,
          searchable: true,
        },
        {
          prop: "city",
          label: "City",
          searchable: true,
        },
        {
          prop: "country",
          label: "Country",
          sortable: true,
          searchable: true,
        },
        {
          prop: "status",
          label: "Status",
          component: "badge",
          variant: {
            active: "fl-success",
            inactive: "fl-danger",
          },
          align: "center",
          headerAlign: "center",
        },
      ],
      rowActions: [
        {
          variant: "warning",
          icon: "fa fa-edit",
          handler: (row, _index, _column) => {
            console.log(row);
            this.showAddAirportModalForm = true;
            this.itemData = row;
            this.editeOption = true;
          },
        },
      ],
      tableData: [
        {
          id: 1,
          code: "EETN",
          city: "Tallinn",
          country: "Estonia",
          status: "active",
          day_only: true,
          image:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Tallinn_Airport_03_July_2006.jpg/500px-Tallinn_Airport_03_July_2006.jpg",
        },
        {
          id: 2,
          code: "EEKE",
          city: "Kuresaare",
          country: "Estonia",
          status: "active",
          day_only: true,
          image:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Tallinn_Airport_03_July_2006.jpg/500px-Tallinn_Airport_03_July_2006.jpg",
        },
        {
          id: 3,
          code: "EFHV",
          city: "Hyvinkää",
          country: "Finland",
          status: "active",
          day_only: true,
          image:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Tallinn_Airport_03_July_2006.jpg/500px-Tallinn_Airport_03_July_2006.jpg",
        },
        {
          id: 4,
          code: "EEPU",
          city: "Pärnu",
          country: "Estonia",
          status: "closed",
          day_only: true,
          image:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Tallinn_Airport_03_July_2006.jpg/500px-Tallinn_Airport_03_July_2006.jpg",
        },
      ],
      showAddAirportModalForm: false,
      loading: false,
      GRANT: GRANT,
      itemData: {},
    };
  },
  computed: {
    dtRef() {
      return this.$refs.dt;
    },
    ...mapGetters("airport", ["airportList"]),
  },
  mounted() {
    this.getAllAirport();
  },
  methods: {
    toggleAddAirportForm(val) {
      this.showAddAirportModalForm = val;
      if (!val) {
        this.itemData = {};
      }
    },
    getAllAirport() {
      this.loading = true;
      airportService.fetchAll().finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
