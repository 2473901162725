<template>
  <section>
    <div class="row">
      <div class="col-12">
        <detail-panel>
          <template #header>Description</template>
          <template #content>
            <div class="row">
              <div class="col-8">
                <base-input>
                  <textarea
                    v-model="formGroup.description.value"
                    rows="7"
                    resize="none"
                    class="form-control"
                  ></textarea>
                </base-input>
                <div class="col-12 p-0">
                  <base-button
                    type="primary"
                    :outline="true"
                    :loading="loading"
                    :disabled="isDisabled"
                    @click="save"
                  >
                    Submit
                  </base-button>
                  <base-button type="default" :outline="true" @click="cancel">
                    Cancel
                  </base-button>
                </div>
              </div>
            </div>
          </template>
        </detail-panel>
      </div>
    </div>
  </section>
</template>

<script>
import DetailPanel from "@views/common/DetailPanel.vue";
import { FormGroup } from "@/core/models";
import { airportService, notificationService } from "@/core/services";
import { Helper } from "@/core/utils";

export default {
  name: "Description",
  components: {
    DetailPanel,
  },
  props: {
    airport: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      formGroup: new FormGroup({
        description: [null],
      }),
      loading: false,
    };
  },
  computed: {
    isDisabled() {
      const value = this.formGroup.description.value;
      return Helper.trim(value).length > 0 ? false : true;
    },
  },
  watch: {
    /* "airport.description": function (newVal, oldVal) {
      console.log("new", newVal);
      console.log("old", oldVal);
    }, */
  },
  mounted() {
    this.formGroup.description.value = this.airport.description;
  },
  methods: {
    save() {
      const payload = {
        description: this.formGroup.description.value,
      };

      this.loading = true;

      airportService
        .saveAttribute(this.airport.id, payload)
        .then(() => {
          notificationService.success(
            "Aiport details has been updated successfully"
          );
        })
        .finally(() => (this.loading = false));
    },
    cancel() {
      this.formGroup.description.value = this.airport.description;
    },
  },
};
</script>

<style lang="scss" scoped></style>
