<template>
  <modal
    :show.sync="isShowModal"
    modal-classes="modal-secondary"
    :backdrop-close="true"
    size="lg"
    @close="close()"
    @show="show()"
  >
    <h6 slot="header" class="modal-title text-uppercase">{{ titleValue }}</h6>
    <validation-observer ref="formValidator">
      <div class="row">
        <div class="col-md-6">
          <base-input
            v-model="formGroup.name.value"
            :rules="formGroup.name.rule"
            label="Name"
            name="Name"
            placeholder="Enter name"
          ></base-input>
        </div>
        <div class="col-md-2">
          <base-input
            v-model="formGroup.code.value"
            :rules="formGroup.code.rule"
            label="Code"
            name="Code"
            placeholder="Code"
            :hide-error-feedback="true"
          ></base-input>
        </div>
        <div class="col-md-4">
          <base-input
            v-model="formGroup.airport_fees.value"
            :rules="formGroup.airport_fees.rule"
            label="Fees"
            name="Fees"
            placeholder="Fees"
          ></base-input>
        </div>
        <div class="col-md-4">
          <base-input
            v-model="formGroup.city.value"
            :rules="formGroup.city.rule"
            label="City"
            name="City"
            placeholder="Enter city"
          ></base-input>
        </div>

        <div class="col-md-4">
          <base-input
            v-model="formGroup.country.value"
            :rules="formGroup.country.rule"
            label="Country"
            name="Country"
            placeholder="Enter country"
          ></base-input>
        </div>
        <div class="col-md-4">
          <base-selectbox
            v-model="formGroup.is_active.value"
            :rules="formGroup.is_active.rule"
            :options="source.status"
            placeholder="Select Status"
            label="Status"
            name="Status"
            size="small"
          ></base-selectbox>
        </div>
        <div class="col-md-3">
          <base-checkbox
            v-model="formGroup.is_day_only.value"
            :rules="formGroup.country.rule"
            label="Country"
            name="Country"
            placeholder="Enter country"
          >
            Day Only
          </base-checkbox>
        </div>

        <div class="col-12 mt-3">
          <h4 class="heading-separator">Location Details</h4>
          <div class="row">
            <div class="col-md-6">
              <base-input
                v-model="formGroup.map_location.value"
                :rules="formGroup.map_location.rule"
                label="Map Link"
                name="Map Link"
                placeholder="Enter Map Link"
              ></base-input>
            </div>
            <div class="col-md-3">
              <base-input
                v-model="formGroup.lat.value"
                :rules="formGroup.lat.rule"
                label="Latitude"
                name="Latitude"
                placeholder="Enter Latitude"
              ></base-input>
            </div>
            <div class="col-md-3">
              <base-input
                v-model="formGroup.long.value"
                :rules="formGroup.long.rule"
                label="Longitude"
                name="Longitude"
                placeholder="Enter Longitude"
              ></base-input>
            </div>
          </div>
        </div>

        <div class="col-12">
          <h3 class="heading-separator">Description</h3>
          <div class="row">
            <div class="col-md-9">
              <base-input>
                <textarea
                  v-model="formGroup.description.value"
                  rows="6"
                  resize="none"
                  class="form-control"
                ></textarea>
              </base-input>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-md-12">
              <label class="form-control-label">Upload Image</label>
            </div>
            <div class="col-md-12 airport-picker">
              <div class="airport-picker__file">
                <airport-file-picker
                  ref="imgFileInput"
                  :airport="item"
                  @select-file="selectFile($event)"
                ></airport-file-picker>
              </div>
              <div class="list-action-btn">
                <div class="list-action-btn__item">
                  <base-button
                    type="primary"
                    size="sm"
                    @click="onBrowseImage()"
                  >
                    <i class="fas fa-upload"></i>
                    Select File
                  </base-button>
                  <base-button
                    v-if="selectedAirportImg"
                    type="danger"
                    size="sm"
                    @click="onRemoveImage()"
                  >
                    <i class="fas fa-times"></i>
                    Remove
                  </base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </validation-observer>
    <template v-slot:footer>
      <base-button type="primary" :loading="loading" @click="saveAirport">
        Submit
      </base-button>
      <base-button
        class="ml-auto"
        type="primary"
        :outline="true"
        @click="close()"
      >
        Close
      </base-button>
    </template>
  </modal>
</template>

<script>
import { FormGroup } from "@/core/models";
import { airportService, notificationService } from "@/core/services";
import AirportFilePicker from "./AirportFilePicker.vue";
export default {
  components: {
    AirportFilePicker,
  },
  props: {
    showAddAirportModalForm: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
    edite: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      modalState: false,
      img: false,
      formGroup: new FormGroup({
        name: [null, { required: true }],
        code: [null, { required: true }],
        airport_fees: [null, { required: true }],
        city: [null, { required: true }],
        country: [null, { required: true }],
        is_active: [null, { required: true }],
        is_day_only: [false],
        map_location: [null, { required: true }],
        lat: [null, { required: true, double: true }],
        long: [null, { required: true, double: true }],
        description: [null],
        image: [null],
      }),
      source: {
        status: [
          { label: "Active", value: "1" },
          { label: "In-Active", value: "0" },
        ],
      },
      titleValue: "Add Airport",
      loading: false,
      selectedAirportImg: null,
    };
  },
  computed: {
    isShowModal: {
      get() {
        return this.showAddAirportModalForm;
      },
      set(value) {
        this.modalState = value;
      },
    },
  },
  methods: {
    async saveAirport() {
      const validate = await this.$refs.formValidator.validate();
      if (validate) {
        this.loading = true;

        if (this.item.id) {
          airportService
            .saveAttribute(this.item.id, {
              airport_fees: this.formGroup.airport_fees.value,
              name: this.formGroup.name.value,
              code: this.formGroup.code.value,
              city: this.formGroup.city.value,
              country: this.formGroup.country.value,
              is_day_only: this.formGroup.is_day_only.value,
              map_location: this.formGroup.map_location.value,
              is_active: this.formGroup.is_active.value == 1 ? "1" : "0",
              lat: this.formGroup.lat.value,
              description: this.formGroup.description.value,
              long: this.formGroup.long.value,
            })
            .then((res) => {
              if (this.selectedAirportImg) {
                return this.$refs.imgFileInput.uploadImage(res.data.id);
              }
              notificationService.success(res.message);
            })
            .finally(() => {
              this.loading = false;
              this.isShowModal = false;
              this.$emit("close");
            });
        } else {
          airportService
            .save(this.formGroup.value)
            .then((res) => {
              notificationService.success(res.message);
              this.close();
              return this.$refs.imgFileInput.uploadImage(res.data.id);
            })
            .finally(() => (this.loading = false));
        }
      }
    },
    onBrowseImage() {
      const fileInputElement =
        this.$refs.imgFileInput.$el.querySelector(".file-img-input");
      fileInputElement.click();
    },
    onRemoveImage() {
      this.$refs.imgFileInput.removeImage();
    },
    selectFile(file) {
      this.selectedAirportImg = file;
    },
    show() {
      if (this.item && this.item.id) {
        this.titleValue = "Edit Airport";
        const formGroup = {
          name: this.item.name,
          code: this.item.code,
          airport_fees: this.item.airport_fees,
          city: this.item.city,
          country: this.item.country,
          is_active: this.item.is_active == "1" ? "Active" : "In-Active",
          is_day_only: this.item.is_day_only,
          map_location: this.item.map_location,
          lat: this.item.lat,
          description: this.item.description,
          long: this.item.long,
        };

        this.formGroup.populate(formGroup);
      } else {
        this.titleValue = "Add Airport";
      }
    },
    close() {
      this.isShowModal = false;
      this.$emit("close");
      this.formGroup.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.heading-separator {
  border-bottom: 1px solid #968888;
  margin-bottom: 1.5em;
  margin-top: 1em;
}

.airport-picker {
  display: flex;
  .list-action-btn {
    &__item {
      display: flex;
      flex-direction: column;
      margin-left: 0.5em;
      button {
        margin-bottom: 0.5em;
        width: 110px;
      }
    }
  }
}
</style>
